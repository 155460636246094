<template>
    <div>
        <sidenav
            :is-sidebar-active.sync="isSidebarActive"
            :item-edit.sync="itemEdit"
            :is-add.sync="isAdd"
            @refetch-data="refetchData"
            :tenant-options="tenantOptions"
        />
        <b-card class="m-2" no-body>
            <div class="p-2">
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label class="text-capitalize">{{ $t("table.show") }} </label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label class="text-capitalize">{{ $t("table.entries") }} </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                :placeholder="$t('table.search')"
                            />
                            <b-button
                                variant="primary"
                                @click="openAdd()"
                                v-if="$can('create', 'Sector')"
                            >
                                <span class="text-nowrap text-capitalize">
                                    {{ $t('safr.addMasterAccount')}}
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refListTable"
                class="position-relative"
                :items="fetchList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('no_record_found')"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #head()="data">
                    <span> {{ $tc(data.label, 1) }}</span>
                </template>

                <template #head(user)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(password)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #cell(masterAccountToken)="data">
                    <div
                        v-if="$can('copy_password', 'SafrMasterAccount')"
                        v-clipboard:copy="`${data.value}`"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        style="cursor: pointer"
                    >
                        <feather-icon
                            icon="CopyIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </div>
                </template>

                <template #head(tenants)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button
                        :id="`actions-${data.item.id}`"
                        tabindex="0"
                        variant="white"
                        class="px-1 btn-y"
                    >
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </b-button>
                    <b-popover
                        :target="`actions-${data.item.id}`"
                        triggers="focus"
                        placement="bottom"
                        custom-class="popover-p-0"
                        variant="white"
                    >
                        <b-list-group class="m-0">

                            <b-list-group-item
                                v-if="$can('update', 'PersonList')"
                                class="border-0"
                                button
                                @click="openEdit(data.item)"
                            >
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{$t("actions.edit")}}
                                </span>
                            </b-list-group-item>

                            <b-list-group-item
                                v-if="$can('delete', 'PersonList')"
                                class="border-0"
                                button
                                @click="confirmDelete(data.item.id)"
                            >
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{$t("actions.delete")}}
                                </span>
                            </b-list-group-item>

                            <b-list-group-item
                                v-if="$can('delete', 'PersonList')"
                                class="border-0"
                                button
                                @click="syncCameras(data.item)"
                            >
                                <feather-icon icon="CameraIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{ $t('safr.update_from_api') }}
                                </span>
                            </b-list-group-item>

                            <b-list-group-item
                                v-if="$can('delete', 'PersonList')"
                                class="border-0"
                                button
                                @click="syncPeople(data.item.id)"
                            >
                                <feather-icon icon="UsersIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{ $t('safr.update_people_from_api') }}
                                </span>
                            </b-list-group-item>

                            <b-list-group-item
                                v-if="$can('delete', 'PersonList')"
                                class="border-0"
                                button
                                @click="syncAccessClearances(data.item.id)"
                            >
                                <feather-icon icon="UsersIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{ $t('safr.update_access_clearance_from_api') }}
                                </span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-popover>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted text-capitalize">
                            {{ $t('table.showing', {from: dataMeta.from,to: dataMeta.to,of: dataMeta.of}) }}
                        </span>
                    </b-col>
                  <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalList"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref,computed, onMounted } from '@vue/composition-api'
import useList from './useList'
import axios from '@/libs/axios'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Sidenav from './Sidenav.vue'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        Sidenav,
        vSelect
    },

    setup(_, context) {
        //Refs
        const popover = ref(null);

        const isSidebarActive = ref(false)
        const itemEdit = ref({})
        const isAdd = ref(false)

        const openEdit = (item) => {
            isSidebarActive.value = true
            itemEdit.value        = JSON.parse(JSON.stringify(item))
            isAdd.value           = false
        }
        const { t } = useI18nUtils()

        const {
            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            people_id,
            dateFrom,
            dateTo,

            tenantOptions,
            tenantFilter,
            contentToast
        } = useList()

        //Methods
        const confirmDelete = function (master_account_id) {
            console.log(master_account_id)
            const self = this;

            this.$swal({
                title: context.parent.$i18n.t("messages.delete.title"),
                text: context.parent.$i18n.t("messages.delete.body"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: context.parent.$i18n.t("actions.delete"),
                cancelButtonText: context.parent.$i18n.t("actions.cancel"),
                customClass: {
                    confirmButton: "btn btn-primary text-capitalize",
                    cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    deleteMasterAccount(master_account_id)
                }
            })
        }

        const deleteMasterAccount = async (master_account_id) => {
            try {
                let { data }    = await axios.delete(`/v1/services/facial-recognition/safr/master-accounts/${master_account_id}`)
                let { message } = data

                contentToast(message,'success')
                refetchData()
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        const onCopy = () => {
            toast({
                component: ToastificationContent,
                props: {
                    title: "Copiado correctamente",
                    icon: "ClipboardIcon",
                    variant: "success",
                }
            });
        }

        const onError = () => {
            toast({
                component: ToastificationContent,
                props: {
                    title: "No se pudo copiar",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                }
            });
        }

        const openAdd = () => {
            isAdd.value = true
            isSidebarActive.value = true
        }

        const peopleSelected = (peopleId) => {
            people_id.value = peopleId
        }

        const setDateFrom = (date) => {
            dateFrom.value = date
        }

        const setDateTo = (date) => {
            dateTo.value = date
        }

        const syncCameras = async (masterAccount) => {
            try {
                let { data } = await axios.post('/v1/services/facial-recognition/safr/master-accounts/cameras/sync', { masterAccount })
                let {message } = data

                contentToast(message,'success')
                refetchData()
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        const syncPeople = async (masterAccountId) => {
            try {
                let { data } = await axios.get(`/v1/services/facial-recognition/safr/master-accounts/${masterAccountId}/people/sync`)
                let {message } = data

                contentToast(message,'success')
                refetchData()
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        const syncAccessClearances = async (masterAccountId) => {
            try {
                let { data } = await axios.get(`/v1/services/facial-recognition/safr/master-accounts/${masterAccountId}/access-clearances/sync`)
                let { message } = data

                contentToast(message,'success')
                refetchData()
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        return {
            // Sidebar
            isSidebarActive,
            isAdd,
            itemEdit,
            openEdit,
            openAdd,
            confirmDelete,

            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            syncCameras,
            syncPeople,
            syncAccessClearances,

            tenantOptions,
            tenantFilter,

            peopleSelected,
            setDateFrom,
            setDateTo,
            popover,
            onCopy,
            onError
        }
    }
}
</script>
