<template>
    <div class="w-100">
        <div v-for="(field, index) in model" :key="index">
            <div class="row mb-1" >
                <div class="col-6 mb-1">
                    <b-form-input 
                        v-model="field.description"
                        size="sm"
                        :placeholder="$t('safr.placeholderDescription')"
                        readonly
                    />
                </div>
                <div class="col-6 mb-1">
                    <b-form-input
                        v-model="field.id"
                        size="sm"
                        :placeholder="$t('safr.placeholderId')"
                        readonly
                    />
                </div>
                <div class="col-12">
                    <b-form-input
                        v-model="field.url"
                        size="sm"
                        :placeholder="$t('safr.placeholderEndpoint')"
                    />
                </div>
            </div>
            <hr class="border">
        </div>
        
    </div>
</template>
  
<script>
//import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
    props: {
        value: Array | Object,
    },
    computed: {
        model: {
            get() {
                return this.value || [];
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    methods: {
        addField() {
            this.value.push({
                description: '', 
                id: '',
                url: '',
                lock: false
            });
        },
        removeField(index) {
            this.value.splice(index, 1);
        }
    }
}
</script>  